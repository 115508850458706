<script>
import { BATCH_TRANSACTIONS_BY_MONTH, BATCHES } from "@/graphql/queries"
import cloneDeep from 'lodash/cloneDeep';
import toInt from 'lodash/toInteger';
import { mapState, mapGetters } from 'vuex';

export default {
  components: {
    apexcharts: () => import('vue-apexcharts'),
  },

  data() {
    return {
      loading: false,
      maxY: 6,
      chartOptions: {
        chart: {
          type: 'line',
          height: 350,
        },

        plotOptions: {
          bar: {
            horizontal: false,
            columnWidth: '55%',
            endingShape: 'rounded',
          },
        },

        dataLabels: {
          enabled: false,
        },

        stroke: {
          show: true,
          width: 3,
          colors: ['transparent'],
          curve: 'smooth',
        },

        title: {
          text: 'Lotação e demanda de pasto do módulo',
          align: 'center',
        },

        yaxis: [{

          labels: {
            formatter: function (val) {
              if (typeof val === 'number')
                return val.toLocaleString(undefined, { useGrouping: true, maximumFractionDigits: 2 });
              return val;
            },
          },

          title: {
            text: 'Demanda (kg de MS/dia)',
          },
        }, 
        {
          opposite: true,
          labels: {
            formatter: function (val) {
              if (typeof val === 'number')
                return val.toLocaleString(undefined, { useGrouping: true, maximumFractionDigits: 2 });
              return val;
            },
          },

          title: {
            text: 'Lotação (UA/ha)'
          }
        }
        ],

        xaxis: {
          categories: [],
        },

        fill: {
          opacity: 1,
        },
      },

      series: [],
    };
  },

  computed: {
    ...mapState('dashboard', ['filter', 'paddocks']),
    ...mapGetters('auth', ['authStatus', 'user']),
  },

  watch: {
    filter() {
      this.fetch();
    },
  },

  methods: {

    async fetch() {
        this.loading = true
        const filter = this.filter
        const arrayStart = this.filter.startAt.split('-');
        const arrayEnd = this.filter.endAt.split('-');
        const start = toInt(arrayStart[1]);
        const end = toInt(arrayEnd[1]);
        const yearStart = toInt(arrayStart[0])
        const yearEnd = toInt(arrayEnd[0])
        let months = ['Jan', 'Fev', 'Mar', 'Abr', 'Maio', 'Jun', 'Jul', 'Ago', 'Set', 'Out', 'Nov', 'Dez'];
        let years = yearEnd - yearStart
        if (years === 0) {
          this.chartOptions.xaxis.categories = months.slice(start - 1, 12 - start);
        } else {
          let arrayMonths = months.slice(start - 1, 12);
          for (let i = 1; i < years - 1; i++) {
            arrayMonths = [...arrayMonths,...months.slice(0, 12)]
          }
          arrayMonths = [...arrayMonths,...months.slice(0, end-1)]
          this.chartOptions.xaxis.categories = arrayMonths
        }
        const series = []
        series[0] = []
        series[1] = []
        years = years + 1
        let startFor = start
        let endFor = 13
        let where = {}
        where.module_id = { _eq: filter.moduleId }
        where.deleted_at = {_is_null: true}
        const { data } = await this.$apollo.query({ query: BATCHES , variables: { where } })
        const batches = data.batches
        const or = []
        for (let index = 0; index < batches.length; index++) {
          or.push({ batch_id: {_eq: batches[index].id} })
        }
        for (let index = 0; index < years; index++){
          if ((index+1) === years) {
            endFor = end
          }
          for (let i = startFor; i < endFor; i++) {
            where = {_and:[], _or:[]}
            let dateStart =  arrayStart[0] + '-' + i.toString() + '-01T00:00:00'
            let dateEnd
            if (i < 12) {
              dateEnd = arrayStart[0] + '-' + (i+1).toString() + '-01T00:00:00'
            } else {
              arrayStart[0] = toInt(arrayStart[0]) + 1
              arrayStart[0] = arrayStart[0].toString()
              dateEnd = arrayStart[0] + '-01' + '-01T00:00:00'
            }
            where._or = or
            where._and.push({ event_date: {_gte: dateStart} })
            where._and.push({ event_date: {_lte: dateEnd} })
            const { data } = await this.$apollo.query({ query: BATCH_TRANSACTIONS_BY_MONTH , variables: { where } })
            const transactions = data.batch_transactions
            let comsumption = 0
            let capacity = 0
            let interval = 0
            for (let i = 0 ; i < transactions.length; i++) {
              if (!(i === 0 && transactions[i].type === 'FECHAMENTO')) {
                comsumption = comsumption + transactions[i].expected_consumption * transactions[i].number_of_heads * transactions[i].interval
                capacity = capacity + transactions[i].module_capacity *  transactions[i].interval
                interval = interval + transactions[i].interval
              }
            }
            comsumption = comsumption / interval
            capacity = capacity / interval
            comsumption = comsumption? comsumption : 0
            capacity = capacity? capacity : 0
            series[0].push(capacity.toFixed(1))
            series[1].push(comsumption.toFixed(0))
          }
          startFor = 1
        }
        this.mountChart(series);
    },

    mountChart(val) {
      const chartOptions = cloneDeep(this.chartOptions);
      const series = [
        {
          name: 'Demanda',
          type: 'column',
          data: val[1],
          color: '#008000',
        },
        {
          name: 'Lotação',
          type: 'line',
          data: val[0],
          color: '#FFA500',
        }
      ];
      chartOptions.colors = series.map((item) => item.color);
      this.chartOptions = chartOptions;
      this.series = series;
      this.maxY = 100
      this.loading = false
    },

    legendClickHandler(chartContext, seriesIndex) {
      let paddockId = this.series[seriesIndex].id;
      if (this.filter.paddockIds && this.filter.paddockIds.length === 1) {
        this.filter.paddockIds = [];
      } else {
        this.filter.paddockIds = [paddockId];
      }
      this.$store.dispatch('dashboard/changeFilter', this.filter);
    },
  },
};
</script>

<template>
  <v-card :loading="loading" class="chatOne">
    <v-card-text>
      <v-row>
        <v-col class="overflow-x-auto">
          <apexcharts v-show="!loading" height="500" class="overflow-chart" :options="chartOptions" :series="series" />
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<style lang="scss">
.apexchartschartxone {
  .apexcharts-tooltip-text-goals-label {
    .apexcharts-tooltip-marker {
      display: none;
    }
  }
}
.overflow-chart {
  min-width: 1000px;
}

.apexcharts-canvas { 
  background-image: url("../../../../../functions/images/mark-small-opacity20.png") !important;
  background-position: center;
  background-repeat:no-repeat;
}
</style>
